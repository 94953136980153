import { z } from 'zod';

import { contractDtoSchema } from '@ygm/common/core/services/dto/contract.dto';

/** Volunteer contract details DTO schema. */
export const volunteerContractDetailsDtoSchema = contractDtoSchema.extend({

	/** Contract's signature. */
	signature: z.string().nullable(),
});

/** Volunteer contract details DTO schema. */
export type VolunteerContractDetailsDto = Readonly<z.infer<typeof volunteerContractDetailsDtoSchema>>;
