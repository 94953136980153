import { inject, Injectable } from '@angular/core';

import { BaseContract } from '../../models/base-contract';
import { BaseContractDto } from '../dto/base-contract.dto';

import { IMapperFromDto } from './mappers';
import { ContractMemberMapper } from './contract-member.mapper';
import { CampaignUserMapper } from './campaign-user.mapper';
import { ContractStatusMapper } from './contract-status.mapper';
import { ContractTypeMapper } from './contract-type.mapper';

/** Base contract mapper. */
@Injectable({ providedIn: 'root' })
export class BaseContractMapper implements IMapperFromDto<BaseContractDto, BaseContract> {

	private readonly contractMemberMapper = inject(ContractMemberMapper);

	private readonly campaignUserMapper = inject(CampaignUserMapper);

	private readonly contractStatusMapper = inject(ContractStatusMapper);

	private readonly contractTypeMapper = inject(ContractTypeMapper);

	/** @inheritdoc */
	public fromDto(dto: BaseContractDto): BaseContract {
		return {
			id: dto.id,
			name: dto.name,
			status: this.contractStatusMapper.fromDto(dto.status),
			member: this.contractMemberMapper.fromDto(dto.member),
			token: dto.token,
			createdBy: dto.created_by != null ? this.campaignUserMapper.fromDto(dto.created_by) : null,
			signedAt: dto.signed_at !== null ? new Date(dto.signed_at) : null,
			approvedAt: dto.approved_at !== null ? new Date(dto.approved_at) : null,
			type: this.contractTypeMapper.fromDto(dto.type),
		};
	}

}
