import { Injectable, inject } from '@angular/core';
import { formatDate } from '@angular/common';

import {
	IMapperToCreationDto,
	IMapperToEditDto,
	IValidationErrorMapper,
} from '@ygm/common/core/services/mappers/mappers';
import { EntityValidationErrors } from '@ygm/common/core/models/app-error';
import { ValidationErrorDto } from '@ygm/common/core/services/dto/validation-error.dto';
import { extractErrorMessageByField } from '@ygm/common/core/services/mappers/extract-error-message';
import { ContractMemberDto } from '@ygm/common/core/services/dto/contract-member.dto';
import { ContractMember } from '@ygm/common/core/models/contract-member';
import { LevelMapper as CommonLevelInstance } from '@ygm/common/core/services/mappers/level.mapper';
import { ContractStatusMapper } from '@ygm/common/core/services/mappers/contract-status.mapper';
import { ContractTypeMapper } from '@ygm/common/core/services/mappers/contract-type.mapper';

import { VolunteerContractCreationData, VolunteerContractEditData } from '../../../models/contract/contract';
import { VolunteerContractCreationDto, VolunteerContractEditDto } from '../../dto/contract/contract.dto';

/** Contract mapper. */
@Injectable({
	providedIn: 'root',
})
export class VolunteerContractMapper
implements
		IMapperToCreationDto<VolunteerContractCreationDto, VolunteerContractCreationData>,
		IMapperToEditDto<VolunteerContractEditDto, VolunteerContractEditData>,
		IValidationErrorMapper<VolunteerContractCreationDto, VolunteerContractCreationData> {
	private readonly commonLevelInstance = inject(CommonLevelInstance);

	private readonly contractStatusMapper = inject(ContractStatusMapper);

	private readonly contractTypeMapper = inject(ContractTypeMapper);

	/** @inheritdoc */
	public toCreationDto(data: VolunteerContractCreationData): VolunteerContractCreationDto {
		return {
			name: data.name,
			levels: data.levels.map(level => this.commonLevelInstance.toInstanceCreationDto(level)),
			member: this.toMemberDto({
				...data.member,
				storedMemberId: data.member.storedMember?.id ?? null,
				storedMember: undefined,
			}),
			private_note: data.privateNote ?? '',
			shared_credits_with: data.sharedCredits,
			type: this.contractTypeMapper.toDto(data.type),
			status: this.contractStatusMapper.toDto(data.status),
			created_date: formatDate(new Date(), 'MM/dd/YYYY', 'en-US'),
		};
	}

	/** @inheritdoc */
	public toEditDto(data: VolunteerContractEditData): VolunteerContractEditDto {
		return {
			name: data.name,
			levels: data.levels.map(level => this.commonLevelInstance.toInstanceCreationDto(level)),
			member: this.toMemberDto({
				...data.member,
				storedMemberId: data.member.storedMember?.id ?? null,
				storedMember: undefined,
			}),
			private_note: data.privateNote ?? '',
			shared_credits_with: data.sharedCredits,
			type: this.contractTypeMapper.toDto(data.type),
			status: this.contractStatusMapper.toDto(data.status),
			created_date: formatDate(new Date(), 'MM/dd/YYYY', 'en-US'),
		};
	}

	/** @inheritdoc */
	public validationErrorFromDto(
		errorDto: ValidationErrorDto<VolunteerContractCreationDto>,
	): EntityValidationErrors<VolunteerContractCreationData> {
		return {
			name: extractErrorMessageByField('name', errorDto),
			levels: extractErrorMessageByField('levels', errorDto),
			member: {
				name: extractErrorMessageByField('member.name', errorDto),
				firstName: extractErrorMessageByField('member.first_name', errorDto),
				lastName: extractErrorMessageByField('member.last_name', errorDto),
				email: extractErrorMessageByField('member.email', errorDto),
				address: extractErrorMessageByField('member.address', errorDto),
				city: extractErrorMessageByField('member.city', errorDto),
				state: extractErrorMessageByField('member.state', errorDto),
				zipcode: extractErrorMessageByField('member.zipcode', errorDto),
				mobilePhone: extractErrorMessageByField('member.mobile_phone', errorDto),
				workPhone: extractErrorMessageByField('member.work_phone', errorDto),
			},
			privateNote: extractErrorMessageByField('note', errorDto),
			sharedCredits: extractErrorMessageByField('shared_credits_with', errorDto),
			type: extractErrorMessageByField('type', errorDto),
		};
	}

	private toMemberDto(dto: ContractMember): ContractMemberDto {
		return {
			name: dto.name,
			first_name: dto.firstName,
			last_name: dto.lastName,
			address: dto.address,
			city: dto.city,
			email: dto.email,
			mobile_phone: dto.mobilePhone,
			state: dto.state,
			work_phone: dto.workPhone,
			zipcode: dto.zipcode,
			stored_member_id: dto.storedMemberId,
		};
	}
}
