import { inject } from '@angular/core';
import {
	Router,
	UrlTree,
} from '@angular/router';
import { first, map, Observable } from 'rxjs';

import { ChamberValidationService } from '@ygm/common/core/services/chamber-validation.service';

import { injectWebAppRoutes } from '../../features/shared/web-route-paths';

/** Guard prevents user from accessing if the current campaign is non-TRC. */
export function isNonTrcGuard(): Observable<boolean | UrlTree> {
	const routePaths = injectWebAppRoutes();
	const router = inject(Router);

	const chamberValidationService = inject(ChamberValidationService);

	return chamberValidationService.chamberInformation$.pipe(
		map(chamberInfo =>
			chamberInfo.campaign && !chamberInfo.campaign.isTrc ? router.parseUrl(routePaths.landing.path) : true),
		first(),
	);
}
