import { Injectable, inject } from '@angular/core';

import { IMapperToDto } from '@ygm/common/core/services/mappers/mappers';
import { BaseFilterParamsMapper } from '@ygm/common/core/services/mappers/base-filter-params.mapper';
import { SortMapper } from '@ygm/common/core/services/mappers/sort.mapper';

import { ContractFilterParamsDto } from '../../dto/contract/contract-filter-params.dto';
import { ContractFilterParams } from '../../../models/contract/contract-filter-params';
import { ContractSortField } from '../../../models/contract/contract-sort-field';
import { ContractSortFieldDto } from '../../dto/contract/contract-sort-field.dto';

const CONTRACT_SORT_FIELD_MAP: Readonly<Record<ContractSortField, ContractSortFieldDto>> = {
	[ContractSortField.Name]: ContractSortFieldDto.Name,
};

/** Contract filter params mapper. */
@Injectable({
	providedIn: 'root',
})
export class ContractFilterParamsMapper implements IMapperToDto<ContractFilterParamsDto, ContractFilterParams> {
	private readonly baseFilterParamsMapper = inject(BaseFilterParamsMapper);

	private readonly sortMapper = inject(SortMapper);

	/** @inheritdoc */
	public toDto(data: ContractFilterParams): ContractFilterParamsDto {
		return {
			...this.baseFilterParamsMapper.toPaginationFilterDto(data),
			...this.baseFilterParamsMapper.toSearchFilterDto(data),
			statuses: data.status,
			is_renewed: data.isRenewed ?? undefined,
			ordering: this.sortMapper.toDto({ direction: data.sortDirection, field: data.sortBy }, CONTRACT_SORT_FIELD_MAP),
		};
	}
}
