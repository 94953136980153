import { inject, Injectable } from '@angular/core';

import { IMapperToDto } from '@ygm/common/core/services/mappers/mappers';
import { ContractPreferredPaymentMethodMapper } from '@ygm/common/core/services/mappers/contract-preferred-payment-method.mapper';

import { ContractSignData } from '../../../models/contract/contract-sign-data';
import { ContractSignDto } from '../../dto/contract/contract-sign.dto';

/** Contract sign data mapper. */
@Injectable({
	providedIn: 'root',
})
export class ContractSignMapper implements IMapperToDto<ContractSignDto, ContractSignData> {

	private readonly contractPreferredPaymentMethodMapper = inject(ContractPreferredPaymentMethodMapper);

	/** @inheritdoc */
	public toDto(data: ContractSignData): ContractSignDto {
		return {
			signature: data.signature,
			level_ids: data.levelIds,
			preferred_payment_method: this.contractPreferredPaymentMethodMapper.toDto(data.preferredPaymentMethod),
		};
	}
}
