import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Observable, map } from 'rxjs';

import { Pagination } from '@ygm/common/core/models/pagination';
import { AppErrorMapper } from '@ygm/common/core/services/mappers/app-error.mapper';
import { ContractActionValidationMapper } from '@ygm/common/core/services/mappers/contract-action-validation.mapper';
import { PaginationMapper } from '@ygm/common/core/services/mappers/pagination.mapper';
import { composeHttpParams } from '@ygm/common/core/utils/compose-http-params';
import { Level } from '@ygm/common/core/models/inventory/level';
import { ContractMapper } from '@ygm/common/core/services/mappers/contract.mapper';
import {
	contractDtoSchema,
	contractPaginationDtoSchema,
} from '@ygm/common/core/services/dto/contract.dto';
import { Contract } from '@ygm/common/core/models/contract';

import { ContractFilterParams } from '../models/contract/contract-filter-params';
import { ContractSignData } from '../models/contract/contract-sign-data';
import { VolunteerContractDetails } from '../models/contract/volunteer-contract-details';
import { VolunteerContractCreationData, VolunteerContractEditData } from '../models/contract/contract';

import { ContractFilterParamsMapper } from './mappers/contract/contract-filter-params.mapper';
import { ContractSignMapper } from './mappers/contract/contract-sign.mapper';
import { PublicAppUrlsConfig } from './public-app-urls.config';
import { volunteerContractDetailsDtoSchema } from './dto/contract/volunteer-contract-details.dto';
import { VolunteerContractMapper } from './mappers/contract/volunteer-contract.mapper';
import { VolunteerContractDetailsMapper } from './mappers/contract/volunteer-contract-details.mapper';

const DEFAULT_CONTRACT_FILTER_PARAMS: ContractFilterParams = {
	pageNumber: 0,
	pageSize: 10,
	search: '',
	isRenewed: null,
	status: '',
	sortBy: null,
	sortDirection: null,
};

/** Contract service. */
@Injectable({
	providedIn: 'root',
})
export class ContractService {
	private readonly httpClient = inject(HttpClient);

	private readonly appErrorMapper = inject(AppErrorMapper);

	private readonly appUrlsConfig = inject(PublicAppUrlsConfig);

	private readonly paginationMapper = inject(PaginationMapper);

	private readonly contractSignMapper = inject(ContractSignMapper);

	private readonly contractFilterParamsMapper = inject(ContractFilterParamsMapper);

	private readonly contractActionValidationMapper = inject(ContractActionValidationMapper);

	private readonly volunteerContractDetailsMapper = inject(VolunteerContractDetailsMapper);

	private readonly contractMapper = inject(ContractMapper);

	private readonly volunteerContractMapper = inject(VolunteerContractMapper);

	/**
	 * Gets contracts list.
	 * @param filterParams Filter params.
	 */
	public getList(filterParams: ContractFilterParams): Observable<Pagination<Contract>> {
		const filtersDto = this.contractFilterParamsMapper.toDto(filterParams);

		const params = composeHttpParams(filtersDto);
		return this.httpClient.get<unknown>(this.appUrlsConfig.contract.list, { params }).pipe(
			map(response => contractPaginationDtoSchema.parse(response)),
			map(dto => this.paginationMapper.fromDto(dto, this.contractMapper)),
		);
	}

	/** Checks whether volunteer has any renewal contracts or not. */
	public hasRenewalContracts(): Observable<boolean> {
		const filtersDto = this.contractFilterParamsMapper.toDto({
			...DEFAULT_CONTRACT_FILTER_PARAMS,
			isRenewed: true,
		});

		const params = composeHttpParams(filtersDto);
		return this.httpClient.get<unknown>(this.appUrlsConfig.contract.list, { params }).pipe(
			map(response => contractPaginationDtoSchema.parse(response)),
			map(dto => this.paginationMapper.fromDto(dto, this.contractMapper)),
			map(page => page.totalCount > 0),
		);
	}

	/**
	 * Gets contract by id.
	 * @param id Contract id.
	 */
	public getById(id: Contract['id']): Observable<Contract> {
		return this.httpClient.get<unknown>(this.appUrlsConfig.contract.entity(id)).pipe(
			map(response => contractDtoSchema.parse(response)),
			map(dto => this.contractMapper.fromDto(dto)),
		);
	}

	/**
	 * Gets contract details.
	 * @param id Contract id.
	 */
	public getDetails(id: Contract['id']): Observable<VolunteerContractDetails> {
		return this.httpClient.get<unknown>(this.appUrlsConfig.contract.internalView(id)).pipe(
			map(response => volunteerContractDetailsDtoSchema.parse(response)),
			map(dto => this.volunteerContractDetailsMapper.fromDto(dto)),
		);
	}

	/**
	 * Creates a contract.
	 * @param data Contract creation data.
	 */
	public create(data: VolunteerContractCreationData): Observable<void> {
		return this.httpClient
			.post<unknown>(this.appUrlsConfig.contract.list, this.volunteerContractMapper.toCreationDto(data))
			.pipe(
				map(() => undefined),
				this.appErrorMapper.catchHttpErrorToAppErrorWithValidationSupport(this.contractMapper),
			);
	}

	/**
	 * Edits a contract.
	 * @param id Contract id.
	 * @param contract Contract edit data.
	 */
	public edit(contract: VolunteerContractEditData): Observable<void> {
		return this.httpClient
			.put<unknown>(this.appUrlsConfig.contract.entity(contract.id), this.volunteerContractMapper.toEditDto(contract))
			.pipe(
				map(() => undefined),
				this.appErrorMapper.catchHttpErrorToAppErrorWithValidationSupport(this.contractMapper),
			);
	}

	/**
	 * Deletes a contract.
	 * @param id Contract id.
	 */
	public delete(id: Contract['id']): Observable<void> {
		return this.httpClient.delete<unknown>(this.appUrlsConfig.contract.entity(id)).pipe(map(() => undefined));
	}

	/**
	 * Gets contract public view details.
	 * @param token Contract token.
	 */
	public getPublicViewDetails(token: string): Observable<VolunteerContractDetails> {
		return this.httpClient.get<unknown>(this.appUrlsConfig.contract.publicView(token)).pipe(
			map(response => volunteerContractDetailsDtoSchema.parse(response)),
			map(dto => this.volunteerContractDetailsMapper.fromDto(dto)),
		);
	}

	/**
	 * Adds level to the contract.
	 * @param contractId Contract's id.
	 * @param levelId Level to add id.
	 */
	public addLevel(contractId: Contract['id'], levelId: Level['id']): Observable<void> {
		return this.httpClient
			.post<unknown>(this.appUrlsConfig.contract.attachLevel(contractId), { level: levelId })
			.pipe(map(() => undefined));
	}

	/**
	 * Signs a contract.
	 * @param token Contract token.
	 * @param signData Contract sign data.
	 */
	public sign(token: string, signData: ContractSignData): Observable<void> {
		return this.httpClient
			.post<unknown>(this.appUrlsConfig.contract.sign(token), this.contractSignMapper.toDto(signData))
			.pipe(
				map(() => undefined),
				this.appErrorMapper.catchHttpErrorToAppErrorWithValidationSupport(this.contractActionValidationMapper),
			);
	}
}
