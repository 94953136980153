import { Injectable } from '@angular/core';

import { EntityValidationErrors } from '@ygm/common/core/models/app-error';
import { ValidationErrorDto } from '@ygm/common/core/services/dto/validation-error.dto';
import { extractErrorMessageByField } from '@ygm/common/core/services/mappers/extract-error-message';
import { IMapperFromDto, IMapperToCreationDto, IMapperToDto, IValidationErrorMapper } from '@ygm/common/core/services/mappers/mappers';
import { UserStatus } from '@ygm/common/core/models/user-status';

import { TeamCreationDto, TeamDto, TeamEditDto, TeamMemberDto } from '../dto/campaign/team.dto';
import { Team, TeamCreationData, TeamMember } from '../../models/campaign/team';
import { CAMPAIGN_USER_ROLE_FROM_DTO_MAP } from '../../../../../../common/src/lib/core/services/mappers/campaign-user-role.mapper';

/** Campaign edit data mapper. */
@Injectable({
	providedIn: 'root',
})
export class TeamMapper implements
IMapperFromDto<TeamDto, Team>,
IMapperToDto<TeamEditDto, Team>,
IValidationErrorMapper<TeamEditDto, Team>,
IMapperToCreationDto<TeamCreationDto, TeamCreationData> {

	/** @inheritdoc */
	public fromDto(dto: TeamDto): Team {
		return {
			id: dto.id,
			name: dto.name,
			teamCaptain: this.fromTeamMemberDto(dto.team_captain),
			campaign: dto.campaign,
			goal: Number(dto.goal),
			members: dto.members.map(memberDto => this.fromTeamMemberDto(memberDto)),
			volunteersCount: dto.volunteers_count,
		};
	}

	/** @inheritdoc */
	public toDto(data: Team): TeamEditDto {
		return {
			name: data.name,
			goal: String(data.goal),
		};
	}

	/** @inheritdoc */
	public validationErrorFromDto(errorDto: ValidationErrorDto<TeamEditDto>): EntityValidationErrors<Team> {
		return {
			name: extractErrorMessageByField('name', errorDto),
			goal: extractErrorMessageByField('goal', errorDto),
		};
	}

	/** @inheritdoc */
	public toCreationDto(data: TeamCreationData): TeamCreationDto {
		return {
			name: data.name,
			goal: String(data.goal),
		};
	}

	private fromTeamMemberDto(dto: TeamMemberDto): TeamMember {
		return {
			id: dto.id ?? null,
			firstName: dto.first_name,
			lastName: dto.last_name,
			campaignId: dto.campaign,
			companyName: dto.company_name,
			email: dto.email,
			userId: dto.user ?? null,
			role: dto.role != null ? CAMPAIGN_USER_ROLE_FROM_DTO_MAP[dto.role] : null,
			teamId: dto.team,
			isActive: dto.is_active ?? false,
			status: dto.is_active ? UserStatus.Active : UserStatus.Inactive,
		};
	}
}
