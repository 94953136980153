/** Contract preferred payment method. */
export enum ContractPreferredPaymentMethod {
	CreditCard = 'creditCard',
	Check = 'check',
	Empty = 'empty',
}

export namespace ContractPreferredPaymentMethod {

	const TO_READABLE: Record<ContractPreferredPaymentMethod, string> = {
		[ContractPreferredPaymentMethod.CreditCard]: 'Credit Card',
		[ContractPreferredPaymentMethod.Check]: 'Check',
		[ContractPreferredPaymentMethod.Empty]: 'Not Selected',
	};

	/**
	 * Provide human-readable value for contract preferred payment method.
	 * @param value Contract preferred payment method.
	 */
	export function toReadable(value: ContractPreferredPaymentMethod): string {
		return TO_READABLE[value];
	}
}
