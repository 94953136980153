import { Injectable, inject } from '@angular/core';

import {
	IMapperFromDto,
} from '@ygm/common/core/services/mappers/mappers';
import { ContractMapper } from '@ygm/common/core/services/mappers/contract.mapper';

import {
	VolunteerContractDetails,
} from '../../../models/contract/volunteer-contract-details';
import {
	VolunteerContractDetailsDto,
} from '../../dto/contract/volunteer-contract-details.dto';

/** Public contract details mapper. */
@Injectable({
	providedIn: 'root',
})
export class VolunteerContractDetailsMapper implements
		IMapperFromDto<VolunteerContractDetailsDto, VolunteerContractDetails> {

	private readonly contractMapper = inject(ContractMapper);

	/** @inheritdoc */
	public fromDto(dto: VolunteerContractDetailsDto): VolunteerContractDetails {
		return {
			...this.contractMapper.fromDto(dto),
			renewalNote: dto.renewal_note,
			contractNote: dto.contract_note,
		};
	}
}
