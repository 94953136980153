import { Injectable } from '@angular/core';

import { IMapper } from '@ygm/common/core/services/mappers/mappers';
import { reverseRecord } from '@ygm/common/core/utils/reverse-record';

import { ContractPreferredPaymentMethodDto } from '../dto/contract-preferred-payment-method.dto';
import { ContractPreferredPaymentMethod } from '../../models/contract-preferred-payment-method';

const FROM_DTO_MAP: Readonly<Record<ContractPreferredPaymentMethodDto, ContractPreferredPaymentMethod>> = {
	[ContractPreferredPaymentMethodDto.CreditCard]: ContractPreferredPaymentMethod.CreditCard,
	[ContractPreferredPaymentMethodDto.Check]: ContractPreferredPaymentMethod.Check,
	[ContractPreferredPaymentMethodDto.Empty]: ContractPreferredPaymentMethod.Empty,
};

const TO_DTO_MAP = reverseRecord(FROM_DTO_MAP);

/** Contract preferred payment method mapper. */
@Injectable({
	providedIn: 'root',
})
export class ContractPreferredPaymentMethodMapper implements IMapper<ContractPreferredPaymentMethodDto, ContractPreferredPaymentMethod> {

	/** @inheritdoc */
	public fromDto(data: ContractPreferredPaymentMethodDto): ContractPreferredPaymentMethod {
		return FROM_DTO_MAP[data];
	}

	/** @inheritdoc */
	public toDto(data: ContractPreferredPaymentMethod): ContractPreferredPaymentMethodDto {
		return TO_DTO_MAP[data];
	}
}
