import { Injectable, inject } from '@angular/core';

import { EntityValidationErrors } from '@ygm/common/core/models/app-error';
import { ValidationErrorDto } from '@ygm/common/core/services/dto/validation-error.dto';
import { extractErrorMessageByField } from '@ygm/common/core/services/mappers/extract-error-message';
import { IMapperFromDetailsDto, IMapperToCreationDto, IMapperToEditDto } from '@ygm/common/core/services/mappers/mappers';
import { CampaignUserMapper as CommonCampaignUserMapper } from '@ygm/common/core/services/mappers/campaign-user.mapper';

import { CampaignChamberAdminInviteData, CampaignUserDetails, CampaignUserEditData, CampaignUserInviteData } from '../../models/campaign/campaign-user';
import { CampaignChamberAdminInviteDto, CampaignUserDetailsDto, CampaignUserEditDto, CampaignUserInviteDto } from '../dto/campaign/campaign-user.dto';
import { CAMPAIGN_USER_ROLE_FROM_DTO_MAP, CAMPAIGN_USER_ROLE_TO_DTO_MAP } from '../../../../../../common/src/lib/core/services/mappers/campaign-user-role.mapper';

import { TeamMapper } from './team.mapper';

/** Campaign mapper. */
@Injectable({
	providedIn: 'root',
})
export class CampaignUserMapper extends CommonCampaignUserMapper implements
IMapperFromDetailsDto<CampaignUserDetailsDto, CampaignUserDetails>,
IMapperToCreationDto<CampaignUserInviteDto, CampaignUserInviteData>,
IMapperToEditDto<CampaignUserEditDto, CampaignUserEditData> {

	private readonly teamMapper = inject(TeamMapper);

	/**
	 * Maps campaign user from details dto.
	 * @param dto Details dto.
	 */
	public fromDetailsDto(dto: CampaignUserDetailsDto): CampaignUserDetails {
		return {
			id: dto.id,
			avatar: dto.avatar ?? null,
			firstName: dto.first_name,
			lastName: dto.last_name,
			email: dto.email,
			role: CAMPAIGN_USER_ROLE_FROM_DTO_MAP[dto.role],
			memberId: dto.member,
			mobilePhone: dto.mobile_phone,
			teamId: dto.team,
			managedTeams: dto.managed_teams.map(teamDto => this.teamMapper.fromDto(teamDto)),
			workPhone: dto.work_phone,
			deactivatedAt: dto.deactivated_at,
			lastLogin: dto.last_login,
			contractsAmount: Number(dto.contract_count),
			companyName: dto.company_name,
			isCloaked: dto.is_cloaked,
			memberName: dto.member_name,
			preferences: {
				facebookUrl: dto.preference.facebook_url,
				instagramUrl: dto.preference.instagram_url,
				linkedinUrl: dto.preference.linkedin_url,
				twitterUrl: dto.preference.twitter_url,
				birthday: dto.birthday,
				homeAddress: dto.home_address,
				homeCity: dto.home_city,
				homeState: dto.home_state,
				homeZipCode: dto.home_zip_code,
				favoriteCandy: dto.preference.favorite_candy,
				favoriteDrink: dto.preference.favorite_drink,
				favoriteMovie: dto.preference.favorite_movie,
				favoriteRestaurant: dto.preference.favorite_restaurant,
				hobbies: dto.preference.hobbies,
			},
		};
	}

	/**
	 * Maps campaign to creation dto.
	 * @param data Creation data.
	 */
	public toEditDto(data: CampaignUserEditData): CampaignUserEditDto {
		return {
			avatar: data.avatar,
			first_name: data.firstName,
			last_name: data.lastName,
			email: data.email,
			role: CAMPAIGN_USER_ROLE_TO_DTO_MAP[data.role],
			member: data.memberId,
			mobile_phone: data.mobilePhone,
			team: data.teamId,
			teams: data.teams,
			work_phone: data.workPhone,
			is_cloaked: data.isCloaked,
		};
	}

	/**
	 * Maps campaign user to creation dto.
	 * @param data Creation data.
	 */
	public toCreationDto(data: CampaignUserInviteData): CampaignUserInviteDto {
		return {
			first_name: data.firstName,
			last_name: data.lastName,
			email: data.email,
			role: CAMPAIGN_USER_ROLE_TO_DTO_MAP[data.role],
			team_manager: data.teamManagerId,
			team: data.teamId,
			member: data.member?.id ?? null,
			mobile_phone: data.mobilePhone,
			work_phone: data.workPhone,
		};
	}

	/**
	 * Maps campaign chamber admin to creation dto.
	 * @param data Chamber admin invite data.
	 */
	public toChamberAdminInviteDto(data: CampaignChamberAdminInviteData): CampaignChamberAdminInviteDto {
		return {
			first_name: data.firstName,
			last_name: data.lastName,
			email: data.email,
			mobile_phone: data.mobilePhone,
		};
	}

	/** @inheritdoc */
	public validationErrorFromEditDto(errorDto: ValidationErrorDto<CampaignUserEditDto>): EntityValidationErrors<CampaignUserDetails> {
		return {
			firstName: extractErrorMessageByField('first_name', errorDto),
			lastName: extractErrorMessageByField('last_name', errorDto),
			email: extractErrorMessageByField('email', errorDto),
			role: extractErrorMessageByField('role', errorDto),
			memberId: extractErrorMessageByField('member', errorDto),
			mobilePhone: extractErrorMessageByField('mobile_phone', errorDto),
			teamId: extractErrorMessageByField('team', errorDto),
			managedTeams: extractErrorMessageByField('team', errorDto),
		};
	}

	/** @inheritdoc */
	public validationErrorFromCreationDto(
		errorDto: ValidationErrorDto<CampaignUserInviteDto>,
	): EntityValidationErrors<CampaignUserInviteData> {
		return {
			firstName: extractErrorMessageByField('first_name', errorDto),
			lastName: extractErrorMessageByField('last_name', errorDto),
			email: extractErrorMessageByField('email', errorDto),
			role: extractErrorMessageByField('role', errorDto),
			teamId: extractErrorMessageByField('team', errorDto),
			teamManagerId: extractErrorMessageByField('team_manager', errorDto),
		};
	}
}
