/** User status. */
export enum UserStatus {
	Active = 'active',
	Inactive = 'inactive',
}

export namespace UserStatus {
	const TO_READABLE: Record<UserStatus, string> = {
		[UserStatus.Active]: 'Active',
		[UserStatus.Inactive]: 'Inactive',
	};

	/**
	 * Provide human-readable value for user status.
	 * @param status User Status.
	 */
	export function toReadable(status: UserStatus): string {
		return TO_READABLE[status];
	}
}
